import React from 'react';
import PropTypes from 'prop-types';

const TaskFilter = ({ filterTasks }) => {
    const handleChange = (event) => {
        filterTasks(event.target.value);
    };

    return (
        <div className="task-filter">
            <select onChange={handleChange} className="form-select">
                <option value="all">Toutes les tâches</option>
                <option value="less24">Tâches - 24h</option>
                <option value="more24">Tâches + 24h</option>
            </select>
        </div>
    );
};

TaskFilter.propTypes = {
    filterTasks: PropTypes.func.isRequired,
};

export default TaskFilter;
