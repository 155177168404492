import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function Rafraichir(){

    const {setUpdateInfos,setLoadTable} = useContext(ModalContext)

    const RefreshButton = () =>{
        setUpdateInfos(true)
        setLoadTable(false)
    }

    return(
        <>
            <button className="btn btn-lg btn-rss" onClick={RefreshButton}>Rafraîchir les données</button>
           
        </>
    )
}

export default Rafraichir