import { useEffect, useState, useContext,useRef } from 'react';
import { createRoot } from 'react-dom/client';
import DataTable from 'datatables.net-dt';
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import ModifierDate from '../../components/Buttons/ModifierDate'
import ValidationType from '../Buttons/ValidationType'
import ActionDetailsProjet from '../Buttons/ActionDetailsProjet'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useParams } from 'react-router-dom';
import axios from "axios"



function TableDetailsProjet() {

    const table = useRef()
    const bodyTable =useRef()

    const { id } = useParams()
    const {
        urlflux, setUrlflux, loading, 
        setLoading, updateInfos,setUpdateInfos,
        setShow,setTypeModal,setIdfluxurl, 
        loadTable,setLoadTable,
        titreTableau,
    } = useContext(ModalContext)
    const [datasTable, setDatasTable] = useState([])
    const [currentTable,setCurrentTable] = useState()
    const [initTable, setInitTable] = useState(false)

    useEffect(() => {
        
        if (updateInfos === false && loading === false) {
            return
        }
        setLoadTable(false)
     
        const interval = setInterval(() => {
            
            axios.get(host + api_url.flux.liste_flux_url + id)
                .then((res) => {
                    var result = res.data
                    setUrlflux([])
                    if (result.flux_url !== null) {
                        var flux_url = result.flux_url
                        flux_url.forEach(element => {
                            setUrlflux(current => [...current, element])
                        });
                       
                        setLoading(false)
                        setUpdateInfos(true)
                           
                    } else {

                        setUrlflux([])
                        if (result.statut !== 1 && result.progress === 1) {
                            setLoading(true)
                        } else if (result.progress === 0 && result.statut === 1) {
                            setLoading(false)
                            setUpdateInfos(true)
                       
                        }
                    }
                }).catch((err) => {
                    console.log(err)
            })

        }, 2000);
       
        return () => {
            setLoadTable(true)
            clearInterval(interval)
        } 

    }, [updateInfos, loading, id, setLoading, setUrlflux]);


    useEffect(() => {
        if (urlflux) {
            setDatasTable([])
            if (urlflux.length > 0) {
                urlflux.forEach(function (element, index) {
                    setDatasTable(current => [...current, element])
                })
            }
        }
       
    }, [urlflux])
   

    useEffect(()=>{

        if(datasTable.length > 0){           
            setCurrentTable(
                new DataTable(table.current, {
                    destroy:true,
                    responsive: true,
                    searching: true,
                    dom: 'Bfrtlip',
                    pageLength: 50,
                    language: {
                        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                        "lengthMenu": "_MENU_",
                        "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                        "paginate": {
                            "previous": '<i class="bi bi-chevron-left"></i>',
                            "next": '<i class="bi bi-chevron-right"></i>'
                        }
                    },
                    data:datasTable,
                    columns:[
                    
                        {
                                data:null,
                                render:function(data,type,row){
                                    if(data.titre != null){
                                        return'<p class="text-center"><strong>URL</strong><br />'+data.url+'</p>'
                                        +'<p><strong>Titre</strong><br />'+data.titre+'</p>'
                                    }else{
                                        return'<p class="text-center"><strong>URL</strong><br />'+data.url+'</p>'
                                    }
                                    

                                }
                        },
                        {
                                data:null,
                                render:function(data,type,row){
                                   let type_validation = (data.type_validation === 'manuel') ? ' Oui (manuel)' : 'Oui (auto)'
                                   if(data.statut_tb ===1){

                                    return type_validation

                                   }else{

                                     return type_validation //+ '<br><a href="#" class="validation_type" data-id='+data.id+'>Modifier</a>'

                                   }
                                  
                                }
                        },
                        {
                                data:null,
                                render:function(data,type,row){
                                    const date_traitement = (data.date_traitement !== null) ? data.date_traitement : '' 
                                    if((data.publie === 0 && data.publie === 0 &&  data.statut_tb === 0)){
                                         
                                        return date_traitement +'<br><a href="#" class="modifier_date" data-id='+data.id+'>Modifier</a>'

                                    }else{

                                        return date_traitement
                                    }
                                   
                                }
                        },
                        {
                            data:null,
                            render:function(data,type,row){

                                let redaction = 'En attente <br><strong>date de traitement</strong>'
                                if (data.date_traitement) {
                                    redaction = 'En attente <strong>rédaction</strong>'
                                }
                                if(data.date_traitement && data.statut_tb ==1){
                                    redaction = 'Rédaction <br><strong>en cours</strong>'
                                }
                                if (data.date_traitement && data.redaction) {
                                    redaction = 'Terminé<br><a href="#">Voir l\'article TB</a>'
                                    if(data.url_tb !=null){
                                        redaction = 'Terminé<br><a href="'+data.url_tb +'" target="_blank">Voir l\'article TB</a>'
                                    }
                                    
                                }

                                return redaction
                                
                            }
                        },
                        {
                            data:null,
                            render:function(data,type,row){
                                
                                let publier = 'En attente <strong>rédaction</strong>'

                                if (data.publie && data.url_redaction) {
                                    publier = 'Oui<br><a href="' + data.url_redaction + '" target="_blank">Voir l\'article</a>'
                                }

                                return publier
                            }
                        },
                        {   
                            data:null,
                            render:function(data,type,row){
                                if(data.type_validation ==='manuel' && data.statut_tb == 0){
                                    return'<div class="dropdown">'
                                            +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                            +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                                +'<li><a class="dropdown-item traiter_manuellement" href="#" data-id='+data.id+' data-id_projet='+data.id_projet+'>Traiter</a></li>'
                                                +'<li><hr class="dropdown-divider"/></li>'
                                                +'<li><a class="dropdown-item supprimer_url_flux" href="#" data-id='+data.id+' data-id_projet='+data.id_projet+'><strong>Supprimer</strong></a></li>'
                                            +'</ul>' 
                                      +'</div>'
                                }else{
                                    return'<div class="dropdown">'
                                            +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                            +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                                +'<li><a class="dropdown-item supprimer_url_flux" href="#" data-id='+data.id+' data-id_projet='+data.id_projet+'>Supprimer</a></li>'
                                            +'</ul>' 
                                      +'</div>'

                                }
                               
                            }
                        },
                    ],
                    drawCallback: function( settings ) {
                        var validation_type = document.getElementsByClassName("validation_type");
                        for (var i = 0; i < validation_type.length; i++) {
                            validation_type[i].addEventListener('click',(e)=>{
                                e.preventDefault()
                                setShow(true)
                                setTypeModal('validation_type')
                                console.log(e.target)
                                setIdfluxurl(e.target.dataset.id)
                                
                            });
                        }
                        var changer_date = document.getElementsByClassName("modifier_date");
                        for (var i = 0; i < changer_date.length; i++) {
                            changer_date[i].addEventListener('click',(e)=>{
                                e.preventDefault()
                                setShow(true)
                                setTypeModal('change_date')
                                setIdfluxurl(e.target.dataset.id)
                            });
                        }

                        var traitement_manuel = document.getElementsByClassName("traiter_manuellement");
                        for (var i = 0; i < traitement_manuel.length; i++) {
                            traitement_manuel[i].addEventListener('click',(e)=>{
                                e.preventDefault()
                                setLoadTable(false)
                                const data = new FormData();
                                data.append('id_flux',e.target.dataset.id)
                                data.append('id_projet',e.target.dataset.id_projet)
                        
                                axios.post(host+api_url.validation.validation_manuel,data)
                                .then((res)=>{
                                    var result = res.data
                                    if(result.statut == 1){
                                        NotificationManager.success(result.msg, 'Succés',2000)
                                        setLoadTable(true)
                                        setUpdateInfos(true)
                                    }else{
                                        NotificationManager.error(result.msg, 'Erreur',2000)
                                        setLoadTable(true)
                                    }
                                })
                                .catch((err)=>{
                                    console.log(err)
                                })
                            
                            });
                        }
                        var supprimer_url_flux = document.getElementsByClassName("supprimer_url_flux");
                        for (var i = 0; i < supprimer_url_flux.length; i++) {
                            supprimer_url_flux[i].addEventListener('click',(e)=>{
                                e.preventDefault()
                                const data = new FormData();
                                data.append('id_flux',e.target.dataset.id)
                                data.append('id_projet',e.target.dataset.id_projet)
                        
                                axios.post(host+api_url.flux.suppression_flux,data)
                                .then((res)=>{
                                    var result = res.data
                                    if(result.statut == 1){
                                        NotificationManager.success(result.msg, 'Succés',2000)
                                        setUpdateInfos(true)
                                    }else{
                                        NotificationManager.error(result.msg, 'Erreur',2000)
                                    }
                                })
                                .catch((err)=>{
                                    console.log(err)
                                })
                            
                            });
                        }
                    }
                    
                })
               
            )
            setInitTable(true)
         
        }else{

            if(initTable){
                currentTable.clear().draw()
            }
            
        }
        
    },[datasTable])


    
 
    return (
        <>
            <div className="row justify-content-center">
                <div className="spinner-border" role="status" style={{display: (loadTable) ? "none" : "block"}}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="row justify-content-center"><h2>{titreTableau}</h2></div>
            <table ref={table} className="table_projet" style={{display: (loadTable) ? "block" : "none"}}>
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th>Type validation</th>
                            <th>Date traitement</th>
                            <th>Rédaction</th>
                            <th>Publié</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref={bodyTable} ><tr><td colSpan={7}>Aucune données disponible</td></tr></tbody>
            </table>
            <NotificationContainer/>
        </>
    );

}
export default TableDetailsProjet