import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import TableTaches from '../Tables/TableTaches'
import NouveauTache from '../Buttons/NouveauTache'
import DisplayModal from '../Modals/DisplayModal'
import { ModalContext } from "../../context/ModalContext"
import { useState,useEffect } from 'react'
import { host, api_url } from "../../constant/url_api"
import axios from 'axios';

function ConfigTache(){

    const [show,setShow] = useState(false)
    const [typeModal,setTypeModal] = useState(null)
    const [listUpdate,setListUpdate] = useState(false)
    const [listeTache, setListeTache] = useState(null)
    const [id_modifer,setId_modifier] = useState(null)

    const closeModal =()=>{

        setShow(false)
        setId_modifier(null)
        
    }
    //initialisation
     useEffect(()=>{

            axios.get(host+api_url.configtache.liste)
            .then((res)=>{
                setListeTache([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                        setListeTache(current =>[...current,element])
                    });
                }else{
                    setListeTache([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })

    },[])
    
    // onUpdate
    useEffect(()=>{
        if(listUpdate){

            axios.get(host+api_url.configtache.liste)
            .then((res)=>{
                setListeTache([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                        setListeTache(current =>[...current,element])
                    });
                }else{
                    setListeTache([])
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
            setListUpdate(false)
        }
    },[listUpdate])

    return(
        <>
         <ModalContext.Provider value={{
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                listUpdate,setListUpdate,
                listeTache, setListeTache,
                id_modifer,setId_modifier
           }}>
                <Header />
                <div className="d-flex flex-row">
                    <Sidebar />
                    <div className="container-fluid p-3 mt-3">
                        <div className="row p-2">
                            <div className="col-md-12">
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <h1 className="titre-page">Configuration tâches</h1>
                                    </div>
                                    <div className="col-md-6 d-flex flex-row align-items-center justify-content-end mt-3">
                                        <NouveauTache />
                                    </div>
                                </div>
                             
                                <TableTaches/>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <DisplayModal />
            </ModalContext.Provider>
        </>
    )
}

export default ConfigTache