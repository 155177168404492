
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import TableProjetsTache from '../../components/Tables/TableProjetsTache'
import NouveauProjet from '../../components/Buttons/NouveauProjet'
import DisplayModal from '../../components/Modals/DisplayModal'
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import { useState,useEffect } from 'react'

import axios from "axios"


function ProjetsTache(){

    const [show,setShow] = useState(false)
    const [typeModal,setTypeModal] = useState(null)
    const [idProjet,setIdProjet] = useState(null)
    const [updateProjet,setUpdateProjet]= useState(false)
    const [listeProjet,setListeProjet]=  useState([])
    const [loadTable,setLoadTable] = useState(true)

    const exemple_data = [
        {
            "nom_projet": "Projet84",
            "nom_thematique": "Test_api",
            "nom_entreprise": "API ENTREPRISE",
            "domaine":"api_test_task_m.com",
            "site_internet": "https://www.api_test_task_m.com",
            "cid": "12345789",
            "url_fiche": "https://www.api_test_task_m.com/fiche",
            "adresse": "Rue api taskM france",
            "telephone": "0348865478",
            "jour_traitement": ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
            "tache_automatique": true
        }
    ];

    const location = "projet"
    const closeModal =()=>{
        setShow(false)
        setIdProjet(null);
    }

    useEffect(()=>{

        axios.get(host+api_url.projet.listeParTache)
        .then((res)=>{
            setListeProjet([])
           
            var datas =res.data
    
            if(datas.length > 0){
               
                datas.forEach(function(element,index){
                  
                    setListeProjet(current=>[...current,element])
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    },[])

    useEffect(()=>{
        if(updateProjet){
           
            axios.get(host+api_url.projet.listeParTache)
            .then((res)=>{
                setListeProjet([])
                var datas =res.data
                if(datas.length > 0){
                    datas.forEach(function(element,index){
                        setListeProjet(current=>[...current,element])
                    })
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            setUpdateProjet(false)
        }

    },[updateProjet])

    return(
        <>
          <ModalContext.Provider value={{
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                idProjet,setIdProjet,
                updateProjet,setUpdateProjet,
                listeProjet,setListeProjet,
                loadTable,setLoadTable,
                location
           }}>
            <Header />
            <div className="d-flex flex-row">
                <Sidebar />
                <div className="container-fluid p-3 mt-3">
                    <div className="row p-2">
                        <div className="col-md-12">
                            <div className="row p-2">
                                <div className="col-md-6">
                                    <h1 className="titre-page">Mes projets</h1>
                                </div>
                                <div className="col-md-6 d-flex flex-row align-items-center justify-content-end">
                                   <NouveauProjet />
                                </div>
                            </div>
                            <TableProjetsTache />
                            <div className="row pt-3">
                                    <div className="col-md-12">
                                        <div className="alert alert-success" role="alert">
                                          <p><strong>Creation projet (methode POST):</strong> <a href="#" target="_blank">https://taskm.yopyo.com/php/public/api/nouveau-projet</a></p>
                                          <p><strong>Exemple de données (format json)</strong> :</p>
                                          <textarea
                                            className="form-control code-display"
                                            rows="21"
                                            value={JSON.stringify(exemple_data, null, 2)}
                                            readOnly
                                         />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <DisplayModal />
            </ModalContext.Provider>
        </>
    )
}

export default ProjetsTache