import { useState,useEffect, useContext } from "react"
import ModalUrlFlux from '../../components/Modals/ModalUrlFlux'
import ModalFiltre from '../../components/Modals/ModalFiltre'
import ModalUrl from '../../components/Modals/ModalUrl'
import ModalValidationAuto from "../../components/Modals/ModalValidationAuto"
import ModalNouveauProjet from "../../components/Modals/ModalNouveauProjet"
import ModalNouveauSite from "../../components/Modals/ModalNouveauSite"
import ModalAjoutSite from "../../components/Modals/ModalAjoutSite"
import ModalModifierDate from "../../components/Modals/ModalModifierDate"
import ModalValidationType from "../../components/Modals/ModalValidationType"
import ModalModifierCategorie from "../../components/Modals/ModalModifierCategorie"
import ModalAjoutTache from "../../components/Modals/ModalAjoutTache"
import ModalAjoutThematique from "../../components/Modals/ModalAjoutThematique"
import { ModalContext } from "../../context/ModalContext"
import ModalNouveauTache from "./ModalNouveauTache"



function DisplayModal(){

    const {typeModal} = useContext(ModalContext)



    const Modal =()=>{

            switch(typeModal){
        
                case 'projet':
                    return  <ModalNouveauProjet />
                    break
                case 'tache':
                    return <ModalAjoutTache />
                    break
                case 'tachePage':
                        return <ModalNouveauTache />
                        break
                case 'thematique':
                    return <ModalAjoutThematique />
                    break
            }   

    }

    return(
        <>
            {Modal()}
        </>
    )
   
}
export default DisplayModal