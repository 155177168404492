
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import TableProjets from '../../components/Tables/TableProjets'
import NouveauProjet from '../../components/Buttons/NouveauProjet'
import DisplayModal from '../../components/Modals/DisplayModal'
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import { useState,useEffect } from 'react'
import axios from "axios"


function Projets(){

    const [show,setShow] = useState(false)
    const [typeModal,setTypeModal] = useState(null)
    const [idProjet,setIdProjet] = useState(null)
    const [updateProjet,setUpdateProjet]= useState(false)
    const [listeProjet,setListeProjet]=  useState([])
    const [loadTable,setLoadTable] = useState(true)

    const closeModal =()=>{

        setShow(false)
        
    }

    useEffect(()=>{

        axios.get(host+api_url.projet.liste)
        .then((res)=>{
            setListeProjet([])
            var datas =res.data
            if(datas.length > 0){
                datas.forEach(function(element,index){
                    setListeProjet(current=>[...current,element])
                })
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    },[])

    useEffect(()=>{
        if(updateProjet){
           
            axios.get(host+api_url.projet.liste)
            .then((res)=>{
                setListeProjet([])
                var datas =res.data
                if(datas.length > 0){
                    datas.forEach(function(element,index){
                        setListeProjet(current=>[...current,element])
                    })
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            setUpdateProjet(false)
        }

    },[updateProjet])

    return(
        <>
          <ModalContext.Provider value={{
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                idProjet,setIdProjet,
                updateProjet,setUpdateProjet,
                listeProjet,setListeProjet,
                loadTable,setLoadTable
           }}>
            <Header />
            <div className="d-flex flex-row">
                <Sidebar />
                <div className="container-fluid p-3 mt-3">
                    <div className="row p-2">
                        <div className="col-md-12">
                            <div className="row p-2">
                                <div className="col-md-6">
                                    <h1 className="titre-page">Mes projets flux</h1>
                                </div>
                                <div className="col-md-6 d-flex flex-row align-items-center justify-content-end">
                                   <NouveauProjet />
                                </div>
                            </div>
                            <TableProjets/>
                        </div>
                    </div>
                   
                </div>
            </div>
            <DisplayModal />
            </ModalContext.Provider>
        </>
    )
}

export default Projets