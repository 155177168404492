import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";

function ModalNouveauProjet() {
  const { show, closeModal, setUpdateProjet, idProjet } =
    useContext(ModalContext);
  const [listeThematique, setListeThematique] = useState(null);
  const [valueNom_projet, setValueNom_projet] = useState("");
  const [valueThematique, setValueThematique] = useState("");
  const [valueNom_entreprise, setValueNom_entreprise] = useState("");
  const [valueSite_internet, setValueSite_internet] = useState("");
  const [valueCid, setValueCid] = useState("");
  const [valueUrl_fiche, setValueUrl_fiche] = useState("");
  const [valueAdresse, setValueAdresse] = useState("");
  const [valueTelephone, setValueTelephone] = useState("");
  const [id_modifer, setIdmodifier] = useState("");
  const [listeTypeTache, setListeTypeTache] = useState(null);

  const onChangeNomProjet = (e, typeChamp) => {
    switch (typeChamp) {
      case "nom_projet":
        setValueNom_projet(e.target.value);
        break;
      case "thematique":
        setValueThematique(e.target.value);
        break;
      case "nom_entreprise":
        setValueNom_entreprise(e.target.value);
        break;
      case "site_internet":
        setValueSite_internet(e.target.value);
        break;
      case "cid":
        setValueCid(e.target.value);
        break;
      case "url_fiche":
        setValueUrl_fiche(e.target.value);
        break;
      case "adresse":
        setValueAdresse(e.target.value);
        break;
      case "telephone":
        setValueTelephone(e.target.value);
        break;
    }
  };
  useEffect(() => {
    if (idProjet) {
      axios
        .get(host + api_url.projet.get + idProjet)
        .then((res) => {
          var data = res.data;
          setValueNom_projet(data.nom_projet);
          setValueThematique(data.id_thematique);
          setValueNom_entreprise(data.nom_entreprise);
          setValueSite_internet(data.site_internet);
          setValueCid(data.cid);
          setValueUrl_fiche(data.url_fiche);
          setValueAdresse(data.adresse);
          setValueTelephone(data.telephone);
          setIdmodifier(idProjet);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [idProjet]);

  useEffect(() => {
    axios
      .get(host + api_url.configthematique.liste)
      .then((res) => {
        setListeThematique(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(host + api_url.configtache.liste)
      .then((res) => {
        setListeTypeTache(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const submitProjet = (e) => {
    e.preventDefault();
    const form = document.querySelector("#nouveauprojetform");
    const data = new FormData(form);
    axios
      .post(host + api_url.projet.nouveau, data)
      .then((res) => {
        var result = res.data;
        if (result.statut == 1) {
          NotificationManager.success(result.msg, "Succés", 2000);
          setUpdateProjet(true);
          setValueNom_projet("");
          setValueThematique("");
          setValueNom_entreprise("");
          setValueSite_internet("");
          setValueCid("");
          setValueUrl_fiche("");
          setValueAdresse("");
          setValueTelephone("");
          setIdmodifier("");
          closeModal();
        } else {
          NotificationManager.error(result.msg, "Erreur", 2000);
          closeModal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeThematique = () => {
    if (listeThematique !== null) {
      return listeThematique.map((tache) => (
        <option key={tache.id} value={tache.id}>
          {tache.nom_thematique}
        </option>
      ));
    }
  };

  const renderTypeTacheCheckboxes = () => {
    console.log('tot', id_modifer)
    
    if (listeTypeTache !== null && id_modifer =="") {

      return listeTypeTache.map((tache) => (
        <div key={tache.id} className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="type_tache[]"
            value={tache.id}
            defaultChecked
          />
          <label className="form-check-label" htmlFor={`type_tache_${tache.id}`}>
            {tache.nom_type_tache}
          </label>
        </div>
      ));

    }else {
      if(id_modifer !=""){
        return (
          <div className="alert alert-warning" role="alert">
            Types de tâche indisponible en mode édition
          </div>
        );
      }else{
        return (
          <div className="alert alert-warning" role="alert">
            Aucun type de tâche disponible . Veuillez configurer les types de tâches.
          </div>
        );
      }
       
    }
  };

  return (
    <>
      <div
        className="modal fadeshow"
        id="modalnouveauprojet"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="exampleModalLabel">
                Créer un projet
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={submitProjet} id="nouveauprojetform">
              <div className="modal-body text-left">
                <div className="form-group d-flex flex-row align-items-center justify-content-around">
                  <label className="w-25">
                    <strong>Nom du Projet * : </strong>
                  </label>
                  <input
                    type="text"
                    name="nom_projet"
                    className="form-control w-75"
                    placeholder="Nom Projet"
                    value={valueNom_projet}
                    onChange={(e) => onChangeNomProjet(e, "nom_projet")}
                    required
                    readOnly={id_modifer !== "" ? true : false}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Thématique* : </strong>
                  </label>
                  <select
                    className="form-control w-75"
                    name="id_thematique"
                    value={valueThematique}
                    onChange={(e) => onChangeNomProjet(e, "thematique")}
                  >
                    <option value="">Séléctionner la thématique</option>
                    {typeThematique()}
                  </select>
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Nom entreprise : </strong>
                  </label>
                  <input
                    type="text"
                    name="nom_entreprise"
                    className="form-control w-75"
                    placeholder="Nom entreprise"
                    value={valueNom_entreprise}
                    onChange={(e) => onChangeNomProjet(e, "nom_entreprise")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Site internet : </strong>
                  </label>
                  <input
                    type="text"
                    name="site_internet"
                    className="form-control w-75"
                    placeholder="Site internet"
                    value={valueSite_internet}
                    onChange={(e) => onChangeNomProjet(e, "site_internet")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>CID : </strong>
                  </label>
                  <input
                    type="text"
                    name="cid"
                    className="form-control w-75"
                    placeholder="cid"
                    value={valueCid}
                    onChange={(e) => onChangeNomProjet(e, "cid")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Url fiche : </strong>
                  </label>
                  <input
                    type="text"
                    name="url_fiche"
                    className="form-control w-75"
                    placeholder="Url fiche"
                    value={valueUrl_fiche}
                    onChange={(e) => onChangeNomProjet(e, "url_fiche")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Adresse : </strong>
                  </label>
                  <input
                    type="text"
                    name="adresse"
                    className="form-control w-75"
                    placeholder="Adresse"
                    value={valueAdresse}
                    onChange={(e) => onChangeNomProjet(e, "adresse")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-2">
                  <label className="w-25">
                    <strong>Téléphone : </strong>
                  </label>
                  <input
                    type="text"
                    name="telephone"
                    className="form-control w-75"
                    placeholder="Téléphone"
                    value={valueTelephone}
                    onChange={(e) => onChangeNomProjet(e, "telephone")}
                  />
                </div>
                <div className="form-group d-flex flex-row align-items-center justify-content-around mt-4">
                  <label className="w-25">
                    <strong>Tâche créer automatiquement : </strong><br/>
                    <small>(1 fois du Lundi au Vendredi entre 9h et 20h)</small>
                  </label>
                  <div className="w-75 d-flex flex-row align-items-center justify-content-between p-3">
                    {renderTypeTacheCheckboxes()}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <input
                  type="hidden"
                  className="form-control"
                  name="id_modifier"
                  value={id_modifer ? id_modifer : ""}
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="type_save"
                  value="formulaire"
                />
                <button
                  type="submit"
                  className="btn btn-rss"
                  data-dismiss="modal"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={show ? "modal-backdrop fade show" : "modal-backdrop fade"}
        style={{ display: show ? "block" : "none" }}
      ></div>
      <NotificationContainer />
    </>
  );
}

export default ModalNouveauProjet;
