import { useContext, useEffect, useState } from "react"
import { ModalContext } from "../../context/ModalContext"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { host, api_url } from "../../constant/url_api"

import axios from "axios"

function ModalNouveauTache(){

 
    const {show,closeModal,setListUpdate,id_modifer,idProjet,location,setUpdateProjet} = useContext(ModalContext)
    const [value_projet,setValue_projet]= useState('')
    const [value_tache,setValue_tache] = useState('')
    const [value_action,setValue_action] = useState('')
    const [value_creneau1,setValue_creneau1] = useState('')
    const [value_creneau2,setValue_creneau2] = useState('')
    const [listeTache, setListeTache] = useState(null)
    const [listeProjet, setListeProjet] = useState(null)
    const [error, setError] = useState('');

    const validateTime = (creneau1, creneau2) => {
        const minTime = "08:00";
        const maxTime = "23:00";
        
        if (creneau1 && (creneau1 < minTime || creneau1 > maxTime)) {
            setError('Le créneau 1 doit être entre 08:00 et 23:00.');
            return false;
        }
        
        if (creneau2 && (creneau2 < minTime || creneau2 > maxTime)) {
            setError('Le créneau 2 doit être entre 08:00 et 23:00.');
            return false;
        }
        
        if (creneau1 && creneau2 && creneau1 >= creneau2) {
            setError('Le créneau 2 doit être supérieur au créneau 1.');
            return false;
        }

        setError('');
        return true;
    };

    const ChangeCreneau1 = (e) => {
        const newCreneau1 = e.target.value;
        setValue_creneau1(newCreneau1);
        validateTime(newCreneau1, value_creneau2);
    };

    const ChangeCreneau2 = (e) => {
        const newCreneau2 = e.target.value;
        setValue_creneau2(newCreneau2);
        validateTime(value_creneau1, newCreneau2);
    };

    const ChangeProjetId=(event)=>{

        setValue_projet(event.target.value);
    }

    const ChangeTacheId=(event)=>{

        setValue_tache(event.target.value);
    }
    const ChangeActionJour=(event)=>{

        setValue_action(event.target.value);
    }
  
    //initialisation
    useEffect(()=>{

            axios.get(host+api_url.projet.liste)
            .then((res)=>{
                setListeProjet([])
                var result = res.data
                if(result != null){
                
                    result.forEach(element => {
                        setListeProjet(current =>[...current,element])
                    });
                }else{
                    setListeProjet([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })

    },[])

    useEffect(()=>{

        axios.get(host+api_url.configtache.liste)
        .then((res)=>{
            setListeTache([])
            var result = res.data
            if(result != null){
            
                result.forEach(element => {
                    setListeTache(current =>[...current,element])
                });
            }else{
                setListeTache([])
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    },[])
    
    const submitSite = (e)=>{

        e.preventDefault()

        if (!validateTime(value_creneau1, value_creneau2)) {
            return;
        }
        
        const form = document.querySelector('#nouveauTacheform');
        const data = new FormData(form);
        axios.post(host+api_url.tache.nouveau,data)
        .then((res)=>{

            var result = res.data
            if(result.statut == 1){
                NotificationManager.success(result.msg, 'Succés',2000)
                
                if(location == "tache"){
                    setListUpdate(true)
                }else{
                    setUpdateProjet(true)
                }
                setValue_projet('')
                setValue_tache('')
                setValue_action('')
                setValue_creneau1('')
                setValue_creneau2('')
                closeModal()
            }else{
                NotificationManager.error(result.msg, 'Erreur',2000)
                closeModal()
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    }

    useEffect(()=>{
        
        if(id_modifer != null){
            axios.get(host+api_url.tache.get+id_modifer)
            .then((res)=>{
               
                var result = res.data
                if(result != null){
          
                    setValue_projet(result.id_projet)
                    setValue_tache(result.id_type_tache)
                    setValue_action(result.action_jour)
                    var creneau = JSON.parse(result.creneau_horaire)
                    setValue_creneau1(creneau.debut)
                    setValue_creneau2(creneau.fin)
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
        }else{
          
            setValue_projet('')
            setValue_tache('')
            setValue_action('')
            setValue_creneau1('')
            setValue_creneau2('')
        }
    },[id_modifer])

    useEffect(()=>{
        if(location == "projet"){
            console.log('test ok')
            setValue_projet(idProjet)
        }
    },[idProjet])

    const projet= ()=>{
        
        if(listeProjet !== null){

            return listeProjet.map((projet) => (
                <option key ={projet.id} value={projet.id}>{projet.nom_projet}</option>
            ));
        }
     
    }

    const typeTache = ()=>{

        if(listeTache !== null){
            return listeTache.map((tache) => (
                <option key ={tache.id} value={tache.id}>{tache.nom_type_tache}</option>
            ));
        }
     
    }


    const renderDaysCheckboxes = () => {
        const days = [
          ['Lundi', 'Lun'],
          ['Mardi', 'Mar'],
          ['Mercredi', 'Mer'],
          ['Jeudi', 'Jeu'],
          ['Vendredi', 'Ven'],
          ['Samedi', 'Sam'],
          ['Dimanche', 'Dim']
        ];
      
        return days.map((day) => (
          <div key={day[1]} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="creneau_jour[]"
              value={day[1]}
              defaultChecked
            />
            <label className="form-check-label" htmlFor={`days_${day[1]}`}>
              {day[0]}
            </label>
          </div>
        ));
      };

  

    
    return(
        <>
            <div className="modal fadeshow" id="modalnouveauTache" style={{display: (show) ? "block" : "none"}}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{(id_modifer)? "Modifier Tache #"+id_modifer : "Créer une tâche"} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={submitSite} id="nouveauTacheform">
                        <div className="modal-body text-left">
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25" ><strong>Projet * : </strong></label>
                                <select  className="form-control w-75" name="id_projet" value={value_projet} onChange={ChangeProjetId}>
                                            <option value="">Séléctionner un projet</option>
                                            {projet()}
                                </select>

                            </div>
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                    <label className="w-25" ><strong>Type tâche * : </strong></label>
                                    <select  className="form-control w-75" name="id_type_tache" value={value_tache} onChange={ChangeTacheId}>
                                             <option value="">Séléctionner un type de tâche</option>
                                             {typeTache()}
                                    </select>

                            </div>
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25"><strong>Action par jours: </strong></label>
                                <input type="number" className="form-control w-75" id="action_jour" name="action_jour" placeholder="Action" autoComplete="on" value={(value_action)?parseFloat(value_action) :''} onChange={ChangeActionJour} required/>
                            </div>
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                 {error && <div className="text-danger mt-2">{error}</div>}
                            </div>
                            
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25"><strong>Créneau horaire * :</strong></label>
                                <small id="emailHelp" className="form-text text-muted w-25">Horaire compris entre 08:00 <br/>et 23:00 uniquement.</small>
                                <input type="time" className="form-control w-25" id="creneau1" name="creneau1"  min="08:00" max="23:00" value={value_creneau1}  onChange={ChangeCreneau1} required />
                                <input type="time" className="form-control w-25" id="creneau2" name="creneau2"   min="08:00" max="23:00" value={value_creneau2} onChange={ChangeCreneau2}  required />
                            </div>
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25">
                                    <strong>Créneau Jour : </strong>
                                </label>
                                <div className="w-75 d-flex flex-row align-items-center justify-content-between">
                                    {renderDaysCheckboxes()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <input type="hidden" className="form-control" name="id_modifier" value={(id_modifer)? id_modifer :""} />
                            <button type="submit" className="btn btn-rss" data-dismiss="modal">Enregistrer</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
            <div className={(show) ? "modal-backdrop fade show" : "modal-backdrop fade"} style={{display: (show) ? "block" : "none"}}></div>
            <NotificationContainer />
        </>
    )
}

export default ModalNouveauTache