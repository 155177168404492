import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import TableSites from '../../components/Tables/TableSites'
import NouveauSite from '../../components/Buttons/NouveauSite'
import DisplayModal from '../../components/Modals/DisplayModal'
import { ModalContext } from "../../context/ModalContext"
import { useState,useEffect } from 'react'
import { host, api_url } from "../../constant/url_api"
import axios from 'axios';

function Sites(){

    const [show,setShow] = useState(false)
    const [typeModal,setTypeModal] = useState(null)
    const [listUpdate,setListUpdate] = useState(false)
    const [listeSite, setListeSite] = useState(null)
    const [id_modifer,setId_modifier] = useState(null)

    const closeModal =()=>{

        setShow(false)
        setId_modifier(null)
        
    }
        //initialisation
     useEffect(()=>{

            axios.get(host+api_url.site.liste)
            .then((res)=>{
                setListeSite([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                      setListeSite(current =>[...current,element])
                    });
                }else{
                    setListeSite([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })

    },[])
    // onUpdate
    useEffect(()=>{
        if(listUpdate){

            axios.get(host+api_url.site.liste)
            .then((res)=>{
                setListeSite([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                      setListeSite(current =>[...current,element])
                    });
                }else{
                    setListeSite([])
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
            setListUpdate(false)
        }
    },[listUpdate])

    return(
        <>
         <ModalContext.Provider value={{
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                listUpdate,setListUpdate,
                listeSite, setListeSite,
                id_modifer,setId_modifier
           }}>
                <Header />
                <div className="d-flex flex-row">
                    <Sidebar />
                    <div className="container-fluid p-3 mt-3">
                        <div className="row p-2">
                            <div className="col-md-12">
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <h1 className="titre-page">Mes sites Wordpress</h1>
                                    </div>
                                    <div className="col-md-6 d-flex flex-row align-items-center justify-content-end">
                                    <NouveauSite />
                                    </div>
                                </div>
                                <TableSites/>
                            </div>
                        </div>
                    </div>
                </div>
                <DisplayModal />
            </ModalContext.Provider>
        </>
    )
}

export default Sites