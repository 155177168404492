import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function OptionAuto(){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const OptionAutoButton = () =>{
        setShow(true)
        setTypeModal('auto')
    }

    return(
        <>
            {   
                <p className="edit_projet" onClick={OptionAutoButton}>Modifier</p>
            }
        </>
    )
}

export default OptionAuto