import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function AjoutUrl({type_button}){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const UrlButton = () =>{
        setShow(true)
        setTypeModal('url')
    }

    return(
        <>
            {
                (type_button == 'new') ?
                    <button className="btn btn-lg btn-rss" onClick={UrlButton}>Ajout Url Manuel</button>
                :
                    <p className="edit_projet" onClick={UrlButton}>Modifier</p>
            }
           
        </>
    )
}

export default AjoutUrl