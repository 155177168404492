import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function NouveauProjet({type_button}){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const NewProjetButton = () =>{
        setShow(true)
        setTypeModal('projet')
    }

    return(
        <>
            <button className="btn btn-lg btn-rss" onClick={NewProjetButton}>Créer nouveau projet</button>
        </>
    )
}

export default NouveauProjet
