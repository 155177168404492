import {useEffect,useState,useContext,useRef} from 'react';
import DataTable from 'datatables.net-dt';
import ActionSite from '../Buttons/ActionSite'
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios"

function TableTaches(){

    const table = useRef()
    const bodyTable =useRef()

    const {listeTache,setShow,setTypeModal,setId_modifier,setListUpdate} = useContext(ModalContext)
    const [datasTable,setDatasTable] = useState([])
    const [currentTable,setCurrentTable] = useState()
    const [initTable, setInitTable] = useState(false)

    useEffect(()=>{

        if(datasTable.length > 0 ){
       
         setCurrentTable(
 
             new DataTable(table.current, {
                 destroy:true,
                 responsive: true,
                 searching: true,
                 dom: 'Bfrtlip',
                 pageLength: 50,
                 language: {
                     "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                     "lengthMenu": "_MENU_",
                     "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                     "paginate": {
                         "previous": '<i class="bi bi-chevron-left"></i>',
                         "next": '<i class="bi bi-chevron-right"></i>'
                     }
                 },
                 data:datasTable,
                 columns:[
                     {data:'id'},
                     {data:'nom_type_tache'},
                     {data:'nom_template_zenno'},
                     {
                         data:null,
                         render:function(data,type,row){
                            
                            return'<div class="dropdown">'
                                +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                    +'<li><a class="dropdown-item modifier_tache" href="#" data-id='+data.id+'>Modifier</a></li>'
                                    +'<li><hr class="dropdown-divider"/></li>'
                                    +'<li><a class="dropdown-item supprimer_tache" href="#" data-id='+data.id+'>Supprimer</a></li>'
                                +'</ul>' 
                            +'</div>'
                         }
                     },
                 ],
                 drawCallback: function( settings ) {
 
                     var modifier_tache = document.getElementsByClassName("modifier_tache");
                     for (var i = 0; i < modifier_tache.length; i++) {
                        modifier_tache[i].addEventListener('click',(e)=>{
                             e.preventDefault()
                             setShow(true)
                             setTypeModal('tache')
                             setId_modifier(e.target.dataset.id)
                             
                         });
                     }
 
                     var supprimer_tache = document.getElementsByClassName("supprimer_tache");
                     for (var i = 0; i < supprimer_tache.length; i++) {
 
                        supprimer_tache[i].addEventListener('click',(e)=>{
 
                            e.preventDefault()
                            const data = new FormData();
                            data.append('id_type_tache',e.target.dataset.id)
                    
                            axios.post(host+api_url.configtache.suppression,data)
                            .then((res)=>{
                                var result = res.data
                                if(result.statut == 1){
                                    NotificationManager.success(result.msg, 'Succés',2000)
                                    setListUpdate(true)
                                }else{
                                    NotificationManager.error(result.msg, 'Erreur',2000)
                                }
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
                             
                         });
                     }
                 
                 }
                 
             })
            
         )
         setInitTable(true)
 
        }else{
            
             if(initTable){
                 currentTable.clear().draw()
             }
        }
 
 
     },[datasTable])

    useEffect(()=>{
  
        if(listeTache){
            setDatasTable([])
            if(listeTache.length > 0){
                listeTache.forEach(function(element,index){
                    setDatasTable(current=>[...current,element])
                })
            }
         
           
        }   
    },[listeTache])

    if(datasTable === null) return null 
    
    return (
            <>
            
               <table ref={table} className="table_site">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Tâches</th>
                            <th>Nom template zenno</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref={bodyTable}><tr><td colSpan={6}>Aucune données disponible</td></tr></tbody>
               </table>
               <NotificationContainer/>
            </>
    );

}
export default TableTaches