import { useEffect, useState, useContext, useRef } from 'react';
import DataTable from 'datatables.net-dt';
import { ModalContext } from "../../context/ModalContext";
import { host, api_url } from "../../constant/url_api";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from "axios";
import TaskFilter from '../Filters/TacheFiltre';

function getFormattedDateTime() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year}.${hours}h${minutes}`;
}

function TableTaches() {
    const table = useRef();
    const bodyTable = useRef();
    const { listeTache, setShow, setTypeModal, setId_modifier, setListUpdate } = useContext(ModalContext);
    const [datasTable, setDatasTable] = useState([]);
    const [currentTable, setCurrentTable] = useState();
    const [initTable, setInitTable] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const filterTasks = (filter) => {
        if (listeTache) {
            let filteredTasks = listeTache;
            const now = new Date();
            const past24 = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            if (filter === 'less24') {
                filteredTasks = listeTache.filter(task => new Date(task.date_demande) >= past24);
            } else if (filter === 'more24') {
                filteredTasks = listeTache.filter(task => new Date(task.date_demande) < past24);
            }
            setDatasTable(filteredTasks);
        }
    };

    useEffect(() => {
        if (datasTable.length > 0) {
            setCurrentTable(
                new DataTable(table.current, {
                    destroy: true,
                    responsive: true,
                    searching: true,
                    dom: 'Bfrtlip',
                    pageLength: 50,
                    language: {
                        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                        "lengthMenu": "_MENU_",
                        "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                        "paginate": {
                            "previous": '<i class="bi bi-chevron-left"></i>',
                            "next": '<i class="bi bi-chevron-right"></i>'
                        }
                    },
                    data: datasTable,
                    columns: [
                        {
                            data: null,
                            orderable: false,
                            render: function (data, type, row) {
                                return `<input type="checkbox" class="select-task" data-id_tache="${data.id_tache}" />`;
                            }
                        },
                        { data: 'id_tache' },
                        { data: 'tache' },
                        { data: 'projet' },
                        { data: 'date_demande' },
                        {
                            data: null,
                            orderable: false,
                            render: function (data, type, row) {
                                return '<p><a href="#" class="tache_ok" data-id_tache=' + data.id_tache + '>OK</a> <a href="#" class="tache_echec" data-id_tache=' + data.id_tache + '>Echec</a></p>';
                            }
                        },
                    ],
                    initComplete: function () {
                        this.api().columns().every(function (index) {
                            if (index !== 0 && index !== 5) { // Skip checkbox and execution columns
                                var column = this;
                                const input = document.getElementById(`filter-input-${index}`);
                                if (input) {
                                    input.onkeyup = function () {
                                        column.search(this.value).draw();
                                    };
                                    column.header().appendChild(input);
                                }
                            }
                        });
                    },
                    drawCallback: function (settings) {
                        var selectTasks = document.getElementsByClassName("select-task");
                        for (var i = 0; i < selectTasks.length; i++) {
                            selectTasks[i].addEventListener('change', (e) => {
                                const taskId = e.target.dataset.id_tache;
                                if (e.target.checked) {
                                    setSelectedTasks(prev => [...prev, taskId]);
                                } else {
                                    setSelectedTasks(prev => prev.filter(id => id !== taskId));
                                }
                            });
                        }

                        var valider_tache = document.getElementsByClassName("tache_ok");
                        for (var i = 0; i < valider_tache.length; i++) {
                            valider_tache[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setListUpdate(false);
                                var idtask = e.target.dataset.id_tache;
                                var today = getFormattedDateTime();
                                axios.get(host + api_url.tache.updateZenno, {
                                    params: {
                                        idtask: idtask,
                                        date: today,
                                        statut: 'ok'
                                    }
                                })
                                    .then((res) => {
                                        var result = res.data;
                                        if (result.statut == 1) {
                                            NotificationManager.success(result.msg, 'Succès', 2000);
                                            setListUpdate(true);
                                        } else {
                                            NotificationManager.error(result.msg, 'Erreur', 2000);
                                            setListUpdate(true);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });

                            });
                        }

                        var annuler_tache = document.getElementsByClassName("tache_echec");
                        for (var i = 0; i < annuler_tache.length; i++) {
                            annuler_tache[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setListUpdate(false);
                                var idtask = e.target.dataset.id_tache;
                                var today = getFormattedDateTime();
                                axios.get(host + api_url.tache.updateZenno, {
                                    params: {
                                        idtask: idtask,
                                        date: today,
                                        statut: 'echec'
                                    }
                                })
                                    .then((res) => {
                                        var result = res.data;
                                        if (result.statut == 1) {
                                            NotificationManager.success(result.msg, 'Succès', 2000);
                                            setListUpdate(true);
                                        } else {
                                            NotificationManager.error(result.msg, 'Erreur', 2000);
                                            setListUpdate(true);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });

                            });
                        }
                    }
                })
            );
            setInitTable(true);
        } else {
            if (initTable) {
                currentTable.clear().draw();
            }
        }
    }, [datasTable]);

    useEffect(() => {
        if (listeTache) {
            setDatasTable([]);
            if (listeTache.length > 0) {
                listeTache.forEach(function (element, index) {
                    setDatasTable(current => [...current, element]);
                });
            }
        }
    }, [listeTache]);

    const handleBulkUpdate = (status) => {
        const today = getFormattedDateTime();
        selectedTasks.forEach((idtask) => {
            axios.get(`${host}${api_url.tache.updateZenno}`, {
                params: {
                    idtask: idtask,
                    date: today,
                    statut: status
                }
            })
                .then((res) => {
                    var result = res.data;
                    if (result.statut == 1) {
                        NotificationManager.success(result.msg, 'Succès', 2000);
                        setListUpdate(true);
                    } else {
                        NotificationManager.error(result.msg, 'Erreur', 2000);
                        setListUpdate(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
        setSelectedTasks([]); // Clear selection after update
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allTaskIds = datasTable.map(task => task.id_tache);
            setSelectedTasks(allTaskIds);
        } else {
            setSelectedTasks([]);
        }

        const checkboxes = document.getElementsByClassName("select-task");
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
        }
    };

    // Create plain HTML input elements and give them IDs
    const filterInputs = [
        <input id="filter-input-1" key="1" style={{ width: '100%' }} placeholder='Filtre ID...' className='form-control' />,
        <input id="filter-input-2" key="2" style={{ width: '100%' }} placeholder='Filtre Tâches...' className='form-control' />,
        <input id="filter-input-3" key="3" style={{ width: '100%' }} placeholder='Filtre Projet...' className='form-control' />,
        <input id="filter-input-4" key="4" style={{ width: '100%' }} placeholder='Filtre Date...' className='form-control' />
    ];

    if (datasTable === null) return null;

    return (
        <>
            <div className="row p-2">
                <div className="col-md-12 d-flex justify-content-end mb-3">
                    {selectedTasks.length > 0 && (
                        <>
                            <button className="btn btn-success m-2" onClick={() => handleBulkUpdate('ok')}>Valider les tâches</button>
                            <button className="btn btn-danger m-2" onClick={() => handleBulkUpdate('echec')}>Annuler les tâches</button>
                        </>
                    )}
                </div>
                <div className="col-md-4 d-flex flex-row align-items-center justify-content-between">
                    <span><strong>Periode </strong></span> <TaskFilter filterTasks={filterTasks} />
                </div>
            </div>
            <div className="row p-2">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                        {filterInputs.map(input => <div key={input.key}>{input}</div>)}
                    </div>
                </div>
            </div>
            <table ref={table} className="table_site">
                <thead>
                    <tr>
                        <th><input type="checkbox" onChange={handleSelectAll} /></th>
                        <th>ID</th>
                        <th>Tâches</th>
                        <th>Projet</th>
                        <th>Date demande</th>
                        <th>Execution</th>
                    </tr>
                </thead>
                <tbody ref={bodyTable}><tr><td colSpan={6}>Aucune données disponible</td></tr></tbody>
            </table>
        </>
    );
}

export default TableTaches;
