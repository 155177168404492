import React, { useState, useEffect } from "react";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";
import flagfr from "../../images/icons/flagfr.png";

function Header() {
  const [stats24, setStats24] = useState(null);
  const [statsmoins24, setStatsmoins24] = useState(null);
  const [tachesEnCours, setTachesEnCours] = useState(null);
  const [tachefaire, setTachefaire] = useState(null);
  const [tachesAVenir, setTachesAVenir] = useState(null);
  //initialisation
  useEffect(() => {
    axios
      .get(host + api_url.tache.statistique)
      .then((res) => {
        setStats24(null);
        setTachesEnCours(null);
        setStatsmoins24(null);
        setTachesAVenir(null);
        var result = res.data;
        console.log('ito',result);
        if (result != null) {
          console.log('ato ve sa tsy ato')
          setStats24(result.plus24);
          setStatsmoins24(result.moins24);
          setTachesEnCours(result.tachesEnCours);
          setTachefaire(result.tachefaire);
          setTachesAVenir(result.tachesAVenir);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container-fluid header">
        <div className="row d-flex flex-row justify-content-stretch align-items-center p-2">
          <div className="col-md-2 d-flex justify-content-start">
            <span className="logo-text-1">TASK</span>
            <span className="logo-text-2">M</span>
          </div>
          <div className="col-md-6 d-flex justify-content-around">
            <p className="task_stat">
              Tâches future : <span>{tachesAVenir}</span>
            </p>
            <p className="task_stat24">
              Tâches en cours : <span>{tachesEnCours}</span>
            </p>
            <p className="task_stat">
              Tâches terminées : <span>{statsmoins24}</span>
            </p>
          </div>
          <div className="col-md-4 d-flex flex-row justify-content-end">
            <div className="d-flex  flex-row justify-content-between align-items-center">
              <span className="text-account m-2">Comptes(s): Compte 1</span>
              <span className="flag m-2">
                <img src={flagfr} width={35} height={25} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
