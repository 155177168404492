import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"
function NouveauFiltre({type_button}){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const FiltreButton = () =>{
        setShow(true)
        setTypeModal('filtre')
    }

    return(
        <>
            {
                 (type_button == 'new') ?
                    <button className="btn btn-lg btn-rss" onClick={FiltreButton}>Nouveau filtre</button>
                 :
                    <p className="edit_projet" onClick={FiltreButton}>Modifier</p>
            }
           
        </>
    )
}

export default NouveauFiltre