import { useContext, useEffect, useState } from "react"
import { ModalContext } from "../../context/ModalContext"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { host, api_url } from "../../constant/url_api"
import axios from "axios"

function ModalNouveauTache(){

    const {show,closeModal,setListUpdate,id_modifer,setId_modifier} = useContext(ModalContext)
    const [tache_modifier,setTache_modifier] =useState(null)
    const [value_tache,setValue_tache]= useState('')
    const [value_template,setValue_template] = useState('')
    
    const submitSite = (e)=>{

        e.preventDefault()
        const form = document.querySelector('#nouveautacheform');
        const data = new FormData(form);
        axios.post(host+api_url.configtache.nouveau,data)
        .then((res)=>{

            var result = res.data
            if(result.statut == 1){
                NotificationManager.success(result.msg, 'Succés',2000)
                setListUpdate(true)
                setId_modifier(null)
                setTache_modifier(null)
                setValue_tache('')
                setValue_template('')
                closeModal()
            }else{
                NotificationManager.error(result.msg, 'Erreur',2000)
                closeModal()
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    }


    useEffect(()=>{
        
        if(id_modifer != null){
            axios.get(host+api_url.configtache.get+id_modifer)
            .then((res)=>{
                setTache_modifier(null)
                var result = res.data
                if(result != null){
          
                    setTache_modifier(result)
                    setValue_tache(result.nom_type_tache)
                    setValue_template(result.nom_template_zenno)
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
        }else{
            setTache_modifier(null)
            setValue_tache('')
            setValue_template('')
        }
    },[id_modifer])


    const ChangeNomTache =(e)=>{
        e.preventDefault()
        setValue_tache(e.target.value)
        
    }

    const ChangeNomTemplateZenno =(e)=>{
        e.preventDefault()
        setValue_template(e.target.value)
    }


    
    return(
        <>
            <div className="modal fadeshow" id="modalnouveautache" style={{display: (show) ? "block" : "none"}}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{(id_modifer)? "Modifier Tache #"+id_modifer : "Créer un type tâche"} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={submitSite} id="nouveautacheform">
                        <div className="modal-body text-left">
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25"><strong>Nom type de tâche: </strong></label>
                                <input type="text" className="form-control w-75" id="nom_type_tache" name="nom_type_tache" placeholder="Type de Tâche" autoComplete="on" onChange={ChangeNomTache} value={value_tache} required/>
                            </div>
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25"><strong>Nom template zenno: </strong></label>
                                <input type="text" className="form-control w-75" id="nom_template_zenno" name="nom_template_zenno" placeholder="Template zenno" autoComplete="on" onChange={ChangeNomTemplateZenno} value={value_template}  required/>
                            </div>
                           
                        </div>
                        <div className="modal-footer">
                            <input type="hidden" className="form-control" name="id_modifier" value={(id_modifer)? id_modifer :""} />
                            <button type="submit" className="btn btn-rss" data-dismiss="modal">Enregistrer</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
            <div className={(show) ? "modal-backdrop fade show" : "modal-backdrop fade"} style={{display: (show) ? "block" : "none"}}></div>
            <NotificationContainer />
        </>
    )
}

export default ModalNouveauTache