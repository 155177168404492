import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function ModifierCategorie(){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const ModifierCategorieButton = () =>{
        setShow(true)
        setTypeModal('modifier_categorie')
    }


        return(
            <>
                 <p className="edit_projet" onClick={ModifierCategorieButton}>Modifier</p>
            </>
        )



}

export default ModifierCategorie