

import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import TableDetailsProjets from '../Tables/TableDetailsProjet'
import NouveauFiltre from '../../components/Buttons/NouveauFiltre'
import NouveauUrlFlux from '../../components/Buttons/NouvelleUrlFlux'
import Rafraichir from '../../components/Buttons/Rafraichir'
import AjoutUrl from '../../components/Buttons/AjoutUrl'
import ModifierCategorie from '../../components/Buttons/ModifierCategorie'
import OptionAuto from '../../components/Buttons/OptionAuto'
import DisplayModal from '../../components/Modals/DisplayModal'
import { ModalContext } from "../../context/ModalContext"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { host, api_url } from "../../constant/url_api"
import axios from "axios"

function DetailsProjets(){
    

    const {id} = useParams()
    
    const [filtre,setFiltre] = useState([])
    const [flux,setFlux] = useState([])
    const [typeModal,setTypeModal] = useState(null)
    const [infosProjet,setInfosProjet] = useState(null)
    const [urlflux,setUrlflux] = useState(null)
    const [idfluxurl,setIdfluxurl] = useState(null)
    const [loading,setLoading] = useState(false)
    const [show,setShow] = useState(false)
    const [updateInfos,setUpdateInfos] = useState(false)
    const [filtreauto,setFiltreauto] =useState([])
    const [activationauto, setActivationauto]= useState(false)
    const [loadTable,setLoadTable] = useState(true)
    const [titreTableau,setTitreTableau] = useState('Liste urls validé')
    const [checkbutton,setCheckbutton] = useState(null)
    const [afterupdateInfos,setAfterupdateInfos] = useState(false)
    const closeModal =()=>{

        setShow(false)
        
    }

    const changeDataTableau = (type_tableau,e)=> {

        e.preventDefault();

        if(type_tableau =='total'){

            axios.get(host+api_url.flux.liste_tous_flux_url + id)
            .then((res)=>{
                var result = res.data
                
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }else{
                  
                    setUrlflux([])
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })

            setTitreTableau('Liste de tous les urls')

        }else if(type_tableau =='filtrer'){

            axios.get(host+api_url.flux.liste_flux_url + id)
            .then((res)=>{
                var result = res.data
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }else{
                  
                    setUrlflux([])
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })

            setTitreTableau('Liste des urls filtré')

        }else if (type_tableau == 'valider'){

            axios.get(host+api_url.flux.liste_valide_flux_url + id)
            .then((res)=>{
                var result = res.data
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }else{
                  
                    setUrlflux([])
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })

            setTitreTableau('Liste des urls validé')
        }
        
    }

    useEffect(()=>{

        axios.get(host+api_url.filtre.liste_filtre + id)
        .then((res)=>{
            var result = res.data
            setFiltre([])
            if(result.filtres != undefined ){
                var filtre_save = JSON.parse(result.filtres)
                filtre_save.forEach(element => {
                  setFiltre(current =>[...current,element])
                });
               // 
            }else{
                setFiltre([])
            }
          
            
        })
        .catch((err)=>{
            console.log(err)
        })

        axios.get(host+api_url.flux.liste_flux + id)
            .then((res)=>{
                var result = res.data
                setFlux([])
                if(result.flux != null ){
                    var flux = JSON.parse(result.flux)
                    flux.forEach(element => {
                      setFlux(current =>[...current,element])
                    });
                }else{
                    setFlux([])
                }
            }).catch((err)=>{
                console.log(err)
        })

        axios.get(host+api_url.flux.liste_flux_url + id)
        .then((res)=>{
            var result = res.data
            if(result.flux_url == null ){
                if(result.statut != 1 && result.progress == 1){
                    setLoading(true)
                }
            }
        }).catch((err)=>{
            console.log(err)
        })

        axios.get(host+api_url.flux.liste_valide_flux_url + id)
            .then((res)=>{
                var result = res.data
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }else{
                  
                    setUrlflux([])
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })

        axios.get(host+api_url.validation.liste_filtre_validation + id)
        .then((res)=>{
                
                var result = res.data
                setFiltreauto([])
                setActivationauto(result.activation)
                if(result.filtres != undefined ){
                    var filtre_save = JSON.parse(result.filtres)
                    filtre_save.forEach(element => {
                        setFiltreauto(current =>[...current,element])
                    });
                   // 
                }else{
                    setFiltreauto([])
                }
        })
        .catch((err)=>{
            console.log(err)
        })

        setTimeout(function(){
            axios.get(host+api_url.projet.get+id)
            .then((res)=>{
               
                setInfosProjet(null)
                    if(res.data){
                        setInfosProjet(res.data)
                    }
            })
            .catch((err)=>{
                console.log(err)
            })
        },1000)
            
        axios.get(host+api_url.flux.get_date + id)
            .then((res)=>{
                var result = res.data
                console.log(res.data)
            })
            .catch((err)=>{
                console.log(err)
            })

            setTimeout(function(){
                axios.get(host+api_url.projet.get+id)
                .then((res)=>{
                    setInfosProjet(null)
                        if(res.data){
                            setInfosProjet(res.data)
                        }
                })
                .catch((err)=>{
                    console.log(err)
                })
            },1000)

    },[])
    
    useEffect(()=>{
        if(updateInfos){
    
            axios.get(host+api_url.filtre.liste_filtre + id)
            .then((res)=>{
                
                var result = res.data
                setFiltre([])
                if(result.filtres != undefined ){
                    var filtre_save = JSON.parse(result.filtres)
                    filtre_save.forEach(element => {
                      setFiltre(current =>[...current,element])
                    });
                   // 
                }else{
                    setFiltre([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })
            axios.get(host+api_url.flux.liste_flux + id)
            .then((res)=>{
                var result = res.data
                setFlux([])
                if(result.flux != null){
                    var flux = JSON.parse(result.flux)
                    flux.forEach(element => {
                      setFlux(current =>[...current,element])
                    });
                }else{
                    setFlux([])
                }
            }).catch((err)=>{
                console.log(err)
            })
            axios.get(host+api_url.flux.liste_flux_url + id)
            .then((res)=>{
                var result = res.data
                if(result.flux_url == null ){
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }else{

                        if(checkbutton == 'flux'){

                            setAfterupdateInfos(true)
                            
                        }
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })
            axios.get(host+api_url.flux.liste_valide_flux_url + id)
            .then((res)=>{
                var result = res.data
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }else{
                  
                    setUrlflux([])
                    if(result.statut != 1 && result.progress == 1){
                        setLoading(true)
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })
            axios.get(host+api_url.validation.liste_filtre_validation + id)
            .then((res)=>{
                
                var result = res.data
                setFiltreauto([])
                setActivationauto(result.activation)
                if(result.filtres != undefined ){
                    var filtre_save = JSON.parse(result.filtres)
                    filtre_save.forEach(element => {
                        setFiltreauto(current =>[...current,element])
                    });
                }else{
                    setFiltreauto([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })
            axios.get(host+api_url.flux.get_date + id)
            .then((res)=>{
                var result = res.data
                console.log(res.data)
            })
            .catch((err)=>{
                console.log(err)
            })
            
            setTimeout(function(){
                axios.get(host+api_url.projet.get+id)
                .then((res)=>{
          
                    setInfosProjet(null)
                        if(res.data){
                            setInfosProjet(res.data)
                        }
                })
                .catch((err)=>{
                    console.log(err)
                })
            },1000)

            setUpdateInfos(false)
            setLoadTable(true)
           
               
            
        }

    },[updateInfos,updateInfos])

    useEffect(()=>{

        if(afterupdateInfos && loading ===false){
            console.log('efa izy fa to')
            axios.get(host+api_url.flux.liste_tous_flux_url + id)
            .then((res)=>{
                var result = res.data
                
                setUrlflux([])
                if(result.flux_url != null ){
                    var flux_url = result.flux_url
                    flux_url.forEach(element => {
                        setUrlflux(current =>[...current,element])
                    });
                   
                }
            }).catch((err)=>{
                console.log(err)
            })
            setCheckbutton(null)
            setAfterupdateInfos(false)
            setTitreTableau('Liste de tous les urls')
        }

    },[afterupdateInfos])



    if(infosProjet === null) return null 
    
    return(
        <>
           <ModalContext.Provider value={{
                id,
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                filtre,setFiltre,
                flux,setFlux,
                urlflux,setUrlflux,
                filtreauto,setFiltreauto,
                updateInfos,setUpdateInfos,
                loading,setLoading,
                idfluxurl,setIdfluxurl,
                loadTable,setLoadTable,
                checkbutton,setCheckbutton,
                activationauto,
                infosProjet,
                titreTableau,setTitreTableau
           }}>
            <Header />
                <div className="d-flex flex-row">
                    <Sidebar />
                    <div className="container-fluid p-3 mt-3">
                        <div className="row p-2">
                            <div className="col-md-12">
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <h1 className="titre-page">{infosProjet.nom}</h1>
                                    </div>
                                    <div className="col-md-6 d-flex flex-row align-items-center justify-content-around">
                                  
                                    <NouveauUrlFlux type_button='new'/>
                                    <NouveauFiltre type_button='new'/>
                                    <AjoutUrl  type_button='new'/>
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div className="col-md-4">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <p className="edit_text">Nombre de flux: <span>{infosProjet.flux}</span></p> 
                                            <NouveauUrlFlux />
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <p className="edit_text">Nombre de filtres: <span>{infosProjet.filtres}</span></p> 
                                            <NouveauFiltre />
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <p className="edit_text">Option sélection automatique: <span>{(activationauto == 1)? 'Activé': 'Désactivé'}</span></p> 
                                            <OptionAuto />
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <p className="edit_text">Catégorie de publication: {infosProjet.categorie_site}</p> 
                                            <ModifierCategorie />
                                        </div>
                                        <Rafraichir/>
                                    </div>
                                    <div className="col-md-8  d-flex flex-row align-items-center justify-content-around">
                                            <a className="stats_projet bouton_tableau" onClick={ (e) => changeDataTableau('valider',e)}>{infosProjet.url_valider}<br/><span>Urls validé</span></a>
                                            <a className="stats_projet bouton_tableau" onClick={ (e) => changeDataTableau('filtrer',e)}>{infosProjet.urls_filtrer}<br/><span>Urls filtré</span></a>
                                            <a className="stats_projet bouton_tableau" onClick={ (e) => changeDataTableau('total',e)}>{infosProjet.urls_total}<br/><span>Urls Total</span></a>
                                    </div>
                                  
                                </div>
                                <div id="table_content" className='text-center'>
                                    <TableDetailsProjets />
                                </div>
                              
                            </div>
                        </div>
                    
                    </div>
                </div>
                <DisplayModal />
          </ModalContext.Provider>
           
        </>
    )
}

export default DetailsProjets