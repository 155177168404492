import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function NouveauUrlFlux({type_button}){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const UrlFluxButton = () =>{
        setShow(true)
        setTypeModal('urlflux')
    }

    return(
        <>
            {   
                (type_button =='new') ?
                   <button className="btn btn-lg btn-rss" onClick={UrlFluxButton}>Nouvelle URL de flux</button>
                :
                   <p className="edit_projet" onClick={UrlFluxButton}>Modifier</p>
            }
        </>
    )
}

export default NouveauUrlFlux