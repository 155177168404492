import { useContext } from "react"
import { ModalContext } from "../../context/ModalContext"

function NouveauTachePage({type_button}){

    const {setShow,setTypeModal} = useContext(ModalContext)

    const NewSiteButton = () =>{
        setShow(true)
        setTypeModal('tachePage')
    }

    return(
        <>
            <button className="btn btn-lg btn-rss" onClick={NewSiteButton}>Créér une tâche</button>
        </>
    )

}

export default NouveauTachePage