import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { ModalContext } from "../../context/ModalContext";
import { useState, useEffect } from "react";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";
import TableTachesAVenir from "../Tables/TableTachesAVenir";
import ModalJsonArray from "../Modals/ModalJsonArray";

function TachesAVenir() {
  const [show, setShow] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [listUpdate, setListUpdate] = useState(false);
  const [listeTache, setListeTache] = useState(null);
  const [id_modifer, setId_modifier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalView, setModalView] = useState(null);
  const location = "tache";

  const closeModal = () => {
    setShow(false);
    setId_modifier(null);
  };

  const handleShowModal = (view) => {
    setModalView(view);
    setShow(true);
  };

  //initialisation
  useEffect(() => {
    axios
      .get(host + api_url.tache.avenir)
      .then((res) => {
        setListeTache([]);
        var result = res.data;
        if (result != null) {
          result.forEach((element) => {
            setListeTache((current) => [...current, element]);
          });
        } else {
          setListeTache([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // onUpdate
  useEffect(() => {
    if (listUpdate) {
      axios
        .get(host + api_url.tache.avenir)
        .then((res) => {
          setListeTache([]);
          var result = res.data;
          if (result != null) {
            result.forEach((element) => {
              setListeTache((current) => [...current, element]);
            });
          } else {
            setListeTache([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setListUpdate(false);
    }
  }, [listUpdate]);

  return (
    <>
      <ModalContext.Provider
        value={{
          show,
          setShow,
          typeModal,
          setTypeModal,
          closeModal,
          listUpdate,
          setListUpdate,
          listeTache,
          setListeTache,
          id_modifer,
          setId_modifier,
          location,
        }}
      >
        <Header />
        <div className="d-flex flex-row">
          <Sidebar />
          <div className="container-fluid p-3 mt-3">
            <div className="row p-2">
              <div className="col-md-12">
                <div className="row p-2">
                  <div className="col-md-6">
                    <h1 className="titre-page">Tâches future</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12"></div>
                  <div className="col-md-12"></div>
                </div>
                {loading ? (
                  <p>Chargement...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <TableTachesAVenir />
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalContext.Provider>
      {show && (
        <ModalJsonArray
          modalView={modalView}
          listeTache={listeTache}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

export default TachesAVenir;
