function NotFound() {
    return (
      <div className="container">
          <div className="row">
              <div className="col-md-12 text-center">
                <h1>404 Page not found</h1>
              </div>
          </div>
      </div>
    );
}
export default NotFound