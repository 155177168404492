import {useEffect,useState,useContext,useRef} from 'react';
import DataTable from 'datatables.net-dt';
import ActionSite from '../Buttons/ActionSite'
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios"
import TaskFilter from '../Filters/TacheFiltre';
import { useLocation } from 'react-router-dom';

function TableTachesPage(){

    const table = useRef()
    const bodyTable =useRef()

    const {listeTache,setShow,setTypeModal,setId_modifier,setListUpdate} = useContext(ModalContext)
    const [datasTable,setDatasTable] = useState([])
    const [currentTable,setCurrentTable] = useState()
    const [initTable, setInitTable] = useState(false)

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const projectFilter = query.get('project');

    useEffect(()=>{

        if(datasTable.length > 0 ){
       
         setCurrentTable(
 
             new DataTable(table.current, {
                 destroy:true,
                 responsive: true,
                 searching: true,
                 dom: 'Bfrtlip',
                 pageLength: 50,
                 language: {
                     "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                     "lengthMenu": "_MENU_",
                     "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                     "paginate": {
                         "previous": '<i class="bi bi-chevron-left"></i>',
                         "next": '<i class="bi bi-chevron-right"></i>'
                     }
                 },
                 data:datasTable,
                 columns:[
                     {data:'id'},
                     {data:'nom_type_tache'},
                     {data:'nom_projet'},
                     {
                        data: null,
                        render:function(data,type,row){
                  
                            return  parseFloat(data.action_jour)

                        }
                     },
                     {
                        data:null,
                        render:function(data,type,row){
                            var horaire = JSON.parse(data.creneau_horaire)
                            return (horaire.debut +' - '+ horaire.fin)
                        }
                     },
                     {
                        data:null,
                        render:function(data,type,row){
                            var jour = JSON.parse(data.creneau_jour)
                            return (jour)
                        }
                     },
                     {
                         data:null,
                         render:function(data,type,row){
                            return'<div class="dropdown">'
                                +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                    +'<li><a class="dropdown-item modifier_tache" href="#" data-id='+data.id+'>Modifier</a></li>'
                                    +'<li><hr class="dropdown-divider"/></li>'
                                    +'<li><a class="dropdown-item supprimer_tache" href="#" data-id='+data.id+' data-id_projet='+data.id_projet+'>Supprimer</a></li>'
                                +'</ul>' 
                            +'</div>'
                         }
                     },
                 ],
                 drawCallback: function( settings ) {
                    attachEventListeners()
                 }
                 
             })
            
         )
         setInitTable(true)
 
        }else{
            
             if(initTable){
                 currentTable.clear().draw()
             }
        }
 
 
     },[datasTable])

     useEffect(() => {
        if (projectFilter && currentTable) {
            currentTable.columns(2).search(projectFilter).draw();
        }
    }, [projectFilter, currentTable]);

    useEffect(()=>{
  
        if(listeTache){
            setDatasTable([])
            if(listeTache.length > 0){
                listeTache.forEach(function(element,index){
                    setDatasTable(current=>[...current,element])
                })
            }
         
           
        }   
    },[listeTache])

    const attachEventListeners = () => {
        // Remove existing event listeners to avoid duplicate execution
        document.querySelectorAll('.modifier_tache').forEach(el => {
            el.replaceWith(el.cloneNode(true));
        });
        document.querySelectorAll('.supprimer_tache').forEach(el => {
            el.replaceWith(el.cloneNode(true));
        });

        var modifier_tache = document.getElementsByClassName("modifier_tache");
        for (var i = 0; i < modifier_tache.length; i++) {
            modifier_tache[i].addEventListener('click', (e) => {
                e.preventDefault();
                setShow(true);
                setTypeModal('tachePage');
                setId_modifier(e.target.dataset.id);
            });
        }

        var supprimer_tache = document.getElementsByClassName("supprimer_tache");
        for (var i = 0; i < supprimer_tache.length; i++) {
            supprimer_tache[i].addEventListener('click', (e) => {
                e.preventDefault();
                const data = new FormData();
                data.append('id_tache', e.target.dataset.id);
                data.append('id_projet', e.target.dataset.id_projet);

                axios.post(host + api_url.tache.suppression, data)
                    .then((res) => {
                        var result = res.data;
                        if (result.statut == 1) {
                            NotificationManager.success(result.msg, 'Succés', 2000);
                            setListUpdate(true);
                        } else {
                            NotificationManager.error(result.msg, 'Erreur', 2000);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }
    };

    if(datasTable === null) return null 
    
    return (
            <>
          
               <table ref={table} className="table_site">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Tâches</th>
                            <th>Projet</th>
                            <th>Action par jours</th>
                            <th>Créneau horaire</th>
                            <th>Créneau jour </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref={bodyTable}><tr><td colSpan={6}>Aucune données disponible</td></tr></tbody>
               </table>
               <NotificationContainer/>
            </>
    );

}
export default TableTachesPage