import React , { useRef } from 'react';

const ModalJsonArray = ({ modalView, listeTache, closeModal }) => {

    const textareaRef = useRef(null);

    const handleCopy = () => {
        if (textareaRef.current) {
            textareaRef.current.select();
            document.execCommand('copy');
        }
    };

    const handlePaste = async () => {
        if (textareaRef.current) {
            const text = await navigator.clipboard.readText();
            textareaRef.current.value = text;
        }
    };

    const renderContent = () => {
        if (modalView === 'array') {
            return (
                <textarea
                    className="form-control code-display"
                    rows="10"
                    value={JSON.stringify(listeTache.map(tache => Object.values(tache)), null, 2)}
                    readOnly
                />
            );
        } else if (modalView === 'json') {
            return (
                <textarea
                    className="form-control code-display"
                    rows="10"
                    value={JSON.stringify(listeTache, null, 2)}
                    readOnly
                />
            );
        }
    };

    return (
        <div className="modal show d-block">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Liste des Tâches</h5>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        {renderContent()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleCopy}>Copier</button>
                        <button type="button" className="btn btn-secondary" onClick={handlePaste}>Coller</button>
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalJsonArray;
